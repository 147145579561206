import React, { useEffect, useState } from 'react'
import { Row, Col, TabPane, Spinner } from 'reactstrap'
import { AuthService } from '@digitalworkflow/dwloginclient'
import {
  PageManager,
  PageEngine,
  StepTabs,
  MetaRefreshType,
  BadgeChangeEvent
} from '@digitalworkflow/dwtranslateclient'
import { projectName } from '../../../constants'
import { RenderHelper } from '../../RenderHelper'

/**
 * Props for [[`PageTab`]] component
 */
export interface PageTabProps {
  /** Tabs info */
  tab: StepTabs.TabInfo

  /** Unique tab number */
  tabIndex: number

  /** Event callback when the number of badge items changes */
  onBadgeChange: (badgeType: string, badgeCount: number) => void
}

/**
 * PageTab component
 *
 * @component PageTab
 * @category PageElements
 */
const PageTab: React.FC<PageTabProps> = ({ tab, tabIndex, onBadgeChange }) => {
  const [components, setComponents] = useState<Array<JSX.Element>>([])
  const [loading, setLoading] = useState(true)
  // Create a render engine helper
  const renderHelper = new RenderHelper()

  useEffect(() => {
    const loadPage = async () => {
      setLoading(true)
      AuthService.setProjectName(projectName)

      const page: PageEngine = await PageManager.instance().findRoute(tab.option1)

      if (page && page.isValid()) {
        await renderPage(page)

        page.evForceRefresh.subscribe((evData: MetaRefreshType) => {
          console.log('Should reload the page....', evData)
          setComponents([])
          renderPage(page)
        })
      }
    }

    loadPage()

    return () => {
      renderHelper.onBadgeChange.cancelAll()
    }
  }, [tab, tabIndex])

  const renderPage = async (page: PageEngine) => {
    renderHelper.onBadgeChange.subscribe((e: BadgeChangeEvent) => {
      onBadgeChange(e.badgeType, e.badgeCount)
    })

    await page.processPage(renderHelper)
    const renderedComponents = renderHelper.currentTarget.map((e: any) => e as JSX.Element)
    setComponents(renderedComponents)
    setLoading(false)
  }

  return (
    <TabPane tabId={tabIndex}>
      <Row>
        <Col sm='12'>
          <div className='mb-0'>
            {loading ? (
              <div className='d-flex justify-content-center'>
                <Spinner color='primary' />
              </div>
            ) : (
              components
            )}
          </div>
        </Col>
      </Row>
    </TabPane>
  )
}

export default PageTab
