import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-balham.css'

import {
  CellClickedEvent,
  CellEditingStoppedEvent,
  ColDef,
  FirstDataRenderedEvent,
  GetContextMenuItemsParams,
  GridSizeChangedEvent,
  IsGroupOpenByDefaultParams,
  MenuItemDef,
  RowClassParams,
  RowGroupOpenedEvent,
  ValueSetterParams
} from 'ag-grid-community'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import React, { ReactElement, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'reactstrap'

import { FindTaskResults, StringOptions, TaskCollection, TimeAgo } from '@digitalworkflow/dwtranslateclient'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { LocalSettings } from '../../../utils/LocalSettings'
import { getTaskListSettings } from './TaskListSettings'
import { GroupRowInnerRenderer } from './TaskListTableRender'
import { TaskListTooltip } from './TaskListTooltip'

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-067139}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Brians_Com_LLC}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{25_November_2025}____[v3]_[01]_MTc2NDAyODgwMDAwMA==138cede0283d1caca2b83e456c6fda4a'
)

export interface TaskListTableProps {
  items: FindTaskResults[]
  options: StringOptions
  onShowHistory?: (task_id: string) => void
  onShowReassignModal?: (task_id: string) => void
  optionShowRejected: boolean
  setSubShowingTask?: (task: FindTaskResults) => void
  renderTaskBadges: (task: FindTaskResults) => ReactElement
}

export interface TaskListColumn {
  headerName: string
  field: string
  align: string
  type: string
  width?: number
}

const columns: TaskListColumn[] = [
  {
    headerName: 'Dash Category',
    field: 'dash_category',
    align: 'left',
    type: 'Text',
    width: 100
  },
  {
    headerName: 'Task Title',
    field: 'task_title',
    align: 'left',
    type: 'Text'
  },
  {
    headerName: 'Workflow State Name',
    field: 'workflow_name',
    align: 'left',
    type: 'Text',
    width: 150
  },
  {
    headerName: 'Create Date',
    field: 'create_dt',
    align: 'right',
    type: 'Date',
    width: 150
  },
  {
    headerName: 'Last Update',
    field: 'updated_dt',
    align: 'right',
    type: 'Date',
    width: 150
  },
  {
    headerName: 'Last Update by',
    field: 'updated_by',
    align: 'left',
    type: 'Text',
    width: 110
  },
  {
    headerName: 'Questions',
    field: 'open_query_count',
    align: 'left',
    type: 'Text',
    width: 80
  },
  {
    headerName: 'Actions',
    field: 'link',
    align: 'left',
    type: 'Action',
    width: 60
  }
]

const TaskListTable = ({
  items,
  options,
  onShowHistory,
  onShowReassignModal,
  optionShowRejected,
  setSubShowingTask,
  renderTaskBadges
}: TaskListTableProps) => {
  const navigate = useNavigate()
  const gridRef = useRef<AgGridReact>(null)
  const [myItems, setMyItems] = React.useState<FindTaskResults[]>([])

  /* Set to true to show a little busy sign when navigating */
  const [isNavigating, setIsNavigating] = React.useState(false)

  // console.log('TaskListTable Setup', items.length)

  useEffect(() => {
    // console.log('UseEffect: Items Changed', items.length, 'vs', myItems.length)

    let gridData = items.filter((item) => item?.dash_category && item?.dash_category.length > 0)

    gridData = gridData.sort((a, b) => {
      return getTaskListSettings(a.dash_category).order - getTaskListSettings(b.dash_category).order
    })

    for (const i of items) {
      console.log('Task', i.task_title, i.workflow_id, i.can_action, i.can_manage, i.can_see, '=', i.task_status)
    }

    if (!optionShowRejected) {
      gridData = gridData.filter((item) => item.dash_category !== 'Rejected' && item.dash_category !== 'Cancelled')
    }

    setMyItems(gridData)
  }, [items])

  console.log('my items', myItems)
  useEffect(() => {
    if (isNavigating) {
      // Change cursor to busy
      document.body.style.cursor = 'wait'

      // Dim the screen
      const overlay = document.createElement('div')
      overlay.style.position = 'fixed'
      overlay.style.top = '0'
      overlay.style.left = '0'
      overlay.style.width = '100%'
      overlay.style.height = '100%'
      overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)'
      overlay.style.zIndex = '9999'
      document.body.appendChild(overlay)

      // Clean up function
      return () => {
        // Reset cursor
        document.body.style.cursor = 'default'

        // Remove overlay
        document.body.removeChild(overlay)
      }
    } else {
      return () => {}
    }
  }, [isNavigating])

  /**
   * Grid cell renderer.
   *
   * @remarks
   * Used for rendering the grid cell values
   */
  const cellRenderFunc = (props: any, col: TaskListColumn) => {
    if (col.field === 'task_title' && props.data?.subtask_ids?.length) {
      return (
        <div className='flex-fill d-flex justify-content-between'>
          {props.value}
          {renderTaskBadges(props.data)}
        </div>
      )
    }

    if (col.field === 'workflow_name' && options.checkOption('Todo')) {
      // Show one output on the task list and another on dashboards
      // This is the Task List for workers
      return (
        <>
          <FontAwesomeIcon icon={['fal', props.data.auser === '' ? 'circle' : 'circle-user']} className='me-2' />
          {props.value}
        </>
      )
    }

    if (col.type === 'Action') {
      return (
        <>
          {props?.data?.route && (
            <Button
              color='primary'
              size='sm'
              data-testid={'taskbutton_' + props.data.task_id}
              className='grid-link-btn'
              onClick={() => {
                setIsNavigating(true)
                navigate(props.data.route)
              }}
            >
              Open
            </Button>
          )}
        </>
      )
    }

    return props.value
  }

  const cellValueFunc = (props: any, col: TaskListColumn) => {
    if (!props.data) {
      return
    }

    if (col.type === 'Action') {
      return
    }

    if (col.type === 'TimeAgo') {
      return props.data[col.field]?.value
        ? TimeAgo.inWords(new Date(props.data[col.field].value)).replace(' ago', '')
        : ''
    }

    if (col.type === 'Date') {
      return props.data[col.field]?.value ? new Date(props.data[col.field].value).toDateString() : ''
    }

    if (col.field === 'workflow_name' && options.checkOption('Dashboard')) {
      // Show one output on the task list and another on dashboards
      // This is the Dashboard for submitters
      return props.data.workflow_fullname
    }

    return props.data[col.field]
  }

  const cellValueSetterFunc = (params: ValueSetterParams) => {
    if (!params.newValue.trim().length) {
      return false
    }
    return true
  }

  const colDefs: ColDef[] = columns.map((col: TaskListColumn) => {
    const baseOptions: ColDef = {
      field: col.field,
      headerName: col.headerName,
      sortable: true,
      unSortIcon: false,
      resizable: true,
      filter: 'agTextColumnFilter',
      filterParams:
        col.field === 'task_title'
          ? {
              textMatcher: ({ filterOption, value, filterText, data }: any) => {
                if (filterText == null) {
                  return false
                }
                const taskId = filterText.slice(5)
                const index = value.lastIndexOf(filterText)
                switch (filterOption) {
                  case 'contains':
                    return value.indexOf(filterText) >= 0 || data.task_id === taskId
                  case 'notContains':
                    return value.indexOf(filterText) < 0 || data.task_id === taskId
                  case 'equals':
                    return value === filterText || data.task_id === taskId
                  case 'notEqual':
                    return value !== filterText || data.task_id === taskId
                  case 'startsWith':
                    return value.indexOf(filterText) === 0 || data.task_id === taskId
                  case 'endsWith':
                    return (index >= 0 && index === value.length - filterText.length) || data.task_id === taskId
                  default:
                    // should never happen
                    console.warn('invalid filter type ', filterOption, value, filterText)
                    return false || data.task_id === taskId
                }
              }
            }
          : undefined,
      rowGroup: false,
      hide: false,
      valueSetter: cellValueSetterFunc,
      valueGetter: (props) => cellValueFunc(props, col),
      cellStyle: {
        textAlign: col.align,
        display: 'flex',
        alignItems: 'center',
        justifyContent: col.align === 'right' ? 'flex-end' : col.align === 'center' ? 'center' : 'flex-start'
      },
      headerClass: 'ag-header-align-' + col.align,
      cellRenderer: (props: any) => {
        return cellRenderFunc(props, col)
      },
      floatingFilter: true,
      editable: col.field === 'task_title',
      tooltipComponent: TaskListTooltip,
      tooltipComponentParams: { color: '#ff00ff' },
      tooltipValueGetter: (params) => (col.type !== 'Action' ? params : undefined)
      // 'Title: ' + params.data.task_title + '\n<br />' + 'Assigned to:' + params.data.auser
      // tooltipField: 'task_title'
    }

    if (col.width) {
      baseOptions.width = col.width
      baseOptions.maxWidth = col.width
      baseOptions.resizable = false
    }

    /** Set the sorting method for Dash Category to use the TaskSettings
    configuration from TaskListSettings.tsx */
    if (col.field === 'dash_category') {
      baseOptions.hide = true
      baseOptions.rowGroup = true
    }

    if (col.field === 'link') {
      baseOptions.sortable = false
      baseOptions.width = 90
      baseOptions.maxWidth = 90
      baseOptions.resizable = false
    }

    return baseOptions
  })

  /** Determines if the row group is expanded by default */
  const isGroupOpenByDefault = (params: IsGroupOpenByDefaultParams<any, any>): boolean => {
    if (params.field === 'dash_category') {
      if (params.key === 'Complete') return false
      if (params.key === 'Rejected') return false
      const collapsedCategories = LocalSettings.taskListCollapsedCategories
      if (collapsedCategories.includes(params.key)) return false
    }
    return true
  }

  const onFirstRender = (params: FirstDataRenderedEvent) => {
    console.log('onFirstRender Called')
    params.api.sizeColumnsToFit()
  }

  const onGridReady = async (params: any) => {
    const gridApi = params.api
    gridApi.sizeColumnsToFit()
    params.api.sizeColumnsToFit()
  }

  /** On screen width change, readjust the grid columns width */
  const onGridViewportResize = (params: GridSizeChangedEvent) => {
    // resizeGridColumns(params)
    params.api.sizeColumnsToFit()
  }

  const onRowGroupOpened = (params: RowGroupOpenedEvent) => {
    const category = params.node.key
    const oldCollapsedCategories = LocalSettings.taskListCollapsedCategories
    if (!category) return
    if (params.expanded && oldCollapsedCategories.includes(category)) {
      LocalSettings.taskListCollapsedCategories = oldCollapsedCategories.filter((c) => c !== category)
    }
    if (!params.expanded && !oldCollapsedCategories.includes(category)) {
      LocalSettings.taskListCollapsedCategories = [...oldCollapsedCategories, category]
    }
  }

  /** On screen width change, readjust the grid columns width */
  const onCellEditingStopped = async (params: CellEditingStoppedEvent) => {
    if (params.valueChanged) {
      console.log('Task title changed for ', params.data.task_id, ': ', params.newValue)
      if (!params.newValue.trim().length) {
        return
      }
      const task = await TaskCollection.findTaskById(params.data.task_id)
      if (task) {
        console.log('Renaming task title:', task)
        task.setTaskTitle(params.newValue)
        task.save()
      }
    }
  }

  const getRowClass = (params: RowClassParams): string => {
    if (params && params.data) {
      const settings = getTaskListSettings(params.data.dash_category)
      return 'tasklist-' + settings.style
    }

    return ''
  }

  /** Return the context menu for the rows in the grid */
  const getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    const result: (string | MenuItemDef)[] = []
    console.log('GetContextMenuCalled')

    if (params.node && params.node.data && params.node.data.task_id && onShowHistory) {
      const task_id = params.node.data.task_id
      result.push({
        name: 'History of task ' + task_id,
        action: () => {
          console.log('Clicked history of task', task_id)
          onShowHistory(task_id)
        }
      })

      const colId = params.column?.getColId()
      const rowIndex = params.node?.rowIndex
      if (colId && rowIndex) {
        result.push({
          name: 'Rename task ' + task_id,
          action: () => {
            console.log('Clicked rename task', task_id, params)
            params.api.startEditingCell({
              rowIndex: rowIndex,
              colKey: colId
            })
          }
        })
      }
    }

    if (
      params.node &&
      params.node.data &&
      params.node.data.task_id &&
      params.node.data.can_manage &&
      onShowReassignModal
    ) {
      const task_id = params.node.data.task_id
      result.push({
        name: 'Reassign task ' + task_id,
        action: () => {
          console.log('Clicked reassign task', task_id)
          onShowReassignModal(task_id)
        }
      })
    }
    if (result.length > 0) result.push('separator')

    if (params.column) {
      result.push('copy')
      result.push('copyWithHeaders')
      result.push('separator')
      result.push('export')
    }

    console.log('node=', params.node)

    return result
  }

  const onCellClicked = (params: CellClickedEvent) => {
    // console.log(params)
    if (params.colDef.field === 'link') {
      // Don't do any action when we click on Action column
      return
    }
    if (setSubShowingTask && params.data?.subtask_ids?.length) {
      setSubShowingTask(params.data)
    }
  }

  return (
    <div className='task-list-table'>
      <div className='ag-theme-balham grid-table'>
        <AgGridReact<any>
          ref={gridRef}
          columnDefs={colDefs}
          onFirstDataRendered={onFirstRender}
          rowSelection='multiple'
          enableRangeSelection
          rowData={myItems}
          pagination={false}
          getRowClass={getRowClass}
          onGridReady={onGridReady}
          onGridSizeChanged={onGridViewportResize}
          isGroupOpenByDefault={isGroupOpenByDefault}
          /** Group Row Related Settings */
          groupDisplayType='groupRows'
          groupMaintainOrder
          groupRowRendererParams={{
            innerRenderer: GroupRowInnerRenderer
          }}
          onRowGroupOpened={onRowGroupOpened}
          getContextMenuItems={getContextMenuItems}
          onCellEditingStopped={onCellEditingStopped}
          tooltipShowDelay={1}
          onCellClicked={onCellClicked}
        />
      </div>
    </div>
  )
}

export default TaskListTable
